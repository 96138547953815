import { useState, useEffect, useRef, useCallback } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { CustomTextRenderer } from "react-pdf/dist/cjs/shared/types";
import { SourceDoc } from "../../types/sourceDoc";
import styles from "./PdfViewer.module.scss";
import { PlusButton } from "../PdfButtons/PlusButton";
import { MinusButton } from "../PdfButtons/MinusButton";
import { CloseButton } from "../PdfButtons/CloseButton";
import { DownloadButton } from "../PdfButtons/DownloadButton";
import { downloadFile } from "../../api";
import { Agent } from "../../types/agent";

import { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { getHeaders } from "../../api";
import { useLogin, getAppServiceToken } from "../../authConfig";
import useWindowDimensions from "../../services/viewportService";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

type PDFFile = string | File | null;

type TextItem = {
    /**
     * - Text content.
     */
    str: string;
    /**
     * - Text direction: 'ttb', 'ltr' or 'rtl'.
     */
    dir: string;
    /**
     * - Transformation matrix.
     */
    transform: Array<any>;
    /**
     * - Width in device space.
     */
    width: number;
    /**
     * - Height in device space.
     */
    height: number;
    /**
     * - Font name used by PDF.js for converted font.
     */
    fontName: string;
    /**
     * - Indicating if the text content is followed by a
     * line-break.
     */
    hasEOL: boolean;

    itemIndex: number;

    pageNumber: number;
};

interface PDFViewerProps {
    onClose: () => void;
    activeCitation: string;
    citationPage: number;
    sourceDocs: SourceDoc;
    agent: Agent;
    darkMode: boolean;
    client: IPublicClientApplication | undefined;
}

type CitationInfo = {
    citationText: string;
    citationSentences: string[];
    citationWords: string[];
};

type TextItemsArray = {
    TextItem: TextItem[];
    pageNumber: number;
};

export const DisplayPdf = (props: PDFViewerProps) => {
    const [numPages, setNumPages] = useState<number>();
    const { width } = useWindowDimensions();
    const isMobile = ()=> { return width < 760; }
    const [pageWidth, setPageWidth] = useState<number>(isMobile() ? width: 600);
    const pdfContainerRef = useRef<HTMLDivElement>(null);
    const [pdfLoaded, setPdfLoaded] = useState<boolean>(false);
    const [textItems, setTextItems] = useState<TextItem[]>([]);
    const [citationInfo, setCitationInfo] = useState<CitationInfo[]>([]);
    const [shouldHighlight, setShouldHighlight] = useState<boolean>(false);
    const [shouldStopHighlight, setShouldStopHighlight] = useState<boolean>(false);
    const [highlightIndex, setHighlightIndex] = useState<number>(0);
    const [stopIndex, setStopIndex] = useState<number>(100);
    const [allTextItems, setAllTextItems] = useState<TextItemsArray[]>([]);
    const [citationObjectUrl, setCitationObjectUrl] = useState("");
    const [currentCitation, setCurrentCitation] = useState<string>("");
    
    function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
        setNumPages(nextNumPages);
        setPdfLoaded(true);
    }

    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }

    const splitToSentences = (citationString: string) => {
        const sentences = citationString.split(/(?<=[.!?])/);
        return sentences;
    };
    const splitToWords = (citationString: string) => {
        const cleanedString = citationString.replace(/[^a-zA-Z\säöå]/g, "");
        const words = cleanedString.split(/\s+/).filter(word => word.trim() !== "");
        return words;
    };

    const scrollToPage = async () => {
        if (pdfLoaded && pdfContainerRef.current) {
            const pageElement = pdfContainerRef.current.querySelector(`[data-page-number="${props.citationPage}"]`) as HTMLElement;
            if (pageElement) {
                await timeout(700);
                pageElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    };

    useEffect(() => {
        if (pdfLoaded) {
            scrollToPage();
        }
    }, [pdfLoaded]);

    useEffect(() => {
        const activePathname = props.activeCitation.split("#")[0];
        const currentPathname = currentCitation.split("#")[0];

        if (activePathname === currentPathname) {
            scrollToPage();
        } else {
            setPdfLoaded(false);
            fetchCitation();
        }
    }, [props.citationPage, props.activeCitation]);

    const handleZoomIn = () => {
        if (pageWidth > 1000) {
            return;
        }
        setPageWidth(pageWidth + 100);
    };

    const handleZoomOut = () => {
        if (pageWidth === 100) {
            return;
        }
        setPageWidth(pageWidth - 100);
    };

    function getTextItemWithNeighbors(textItems: TextItem[], itemIndex: any, span = 2) {
        return textItems
            .slice(Math.max(0, itemIndex - span), itemIndex + 1 + span)
            .filter(Boolean)
            .map(item => item.str.replace(/\s+/g, "").replace(/[^a-zA-Z\säöåÅÄÖ]/g, ""))
            .join("");
    }

    function getStopTextItemWithNeighbors(textItems: TextItem[], itemIndex: any, span = 2) {
        return textItems
            .slice(Math.max(0, itemIndex - span), itemIndex + 1 + span)
            .filter(Boolean)
            .map(item => item.str.replace(/\s+/g, "").replace(/[^a-zA-Z\säöåÅÄÖ]/g, ""))
            .join("");
    }

    function highlightPattern(text: string, pattern: string) {
        return text.replace(pattern, value => `<mark>${value}</mark>`);
    }

    const handleDownload = async () => {
        const token = props.client ? await getAppServiceToken(props.client) : undefined;
        downloadFile(token, props.activeCitation, props.agent.type);
    };

    const onPageLoadSuccess = useCallback(
        async (page: any) => {
            const textContentPage = await page.getTextContent();
            const textItemsPage = textContentPage.items;
            const pageNumber = (await page._pageIndex) + 1;
            setAllTextItems(prevAllTextItems => [...prevAllTextItems, { TextItem: textItemsPage, pageNumber: pageNumber }]);
            if (pageNumber !== props.citationPage) {
                return;
            }
            const citationInfoArray: CitationInfo[] = [];

            if (props.sourceDocs.metadata.pageNumber === props.citationPage) {
                const citationText = props.sourceDocs.pageContent;
                const citationSentences = splitToSentences(citationText);
                const citationWords = splitToWords(citationText);
                citationInfoArray.push({
                    citationText,
                    citationSentences,
                    citationWords
                });
            }

            setCitationInfo(citationInfoArray);
            setTextItems(textItemsPage);
            setShouldHighlight(false);
            return;
        },
        [props.citationPage, props.sourceDocs]
    );

    useEffect(() => {
        const updateCitations = () => {
            setShouldHighlight(false);
            setStopIndex(50);
            setCitationInfo([]);
            setShouldStopHighlight(false);

            if (!props.sourceDocs) {
                setCitationInfo([]);
                return;
            }
            const citationInfoArray: CitationInfo[] = [];

            if (props.sourceDocs.metadata.pageNumber === props.citationPage) {
                const citationText = props.sourceDocs.pageContent;
                const citationSentences = splitToSentences(citationText);
                const citationWords = splitToWords(citationText);

                citationInfoArray.push({
                    citationText,
                    citationSentences,
                    citationWords
                });
            }

            for (const textItem of allTextItems) {
                if (textItem.pageNumber === props.citationPage) {
                    setTextItems(textItem.TextItem);
                }
            }
            setCitationInfo(citationInfoArray);
        };
        updateCitations();
    }, [props.citationPage, props.sourceDocs]);

    const customTextRendererIndexHighlight: CustomTextRenderer = useCallback(
        (textItem: TextItem) => {
            if (!textItems) {
                return "";
            }

            const { pageNumber } = textItem;
            const { itemIndex } = textItem;

            if (pageNumber === props.citationPage) {
                const matchString = citationInfo[0].citationWords
                    .slice(0, 2)
                    .join("")
                    .replace(/\s+/g, "")
                    .replace(/[^a-zA-Z\säöåÅÄÖ]/g, "");
                const stopString = citationInfo[0].citationWords
                    .slice(-2)
                    .join("")
                    .replace(/\s+/g, "")
                    .replace(/[^a-zA-Z\säöåÅÄÖ]/g, "");
                const sanitizedTextItemStr = textItem.str.replace(/\s+/g, "").replace(/[^a-zA-Z\säöåÅÄÖ]/g, "");

                const textWithNeighbors = getTextItemWithNeighbors(textItems, itemIndex);
                const stopTextWithNeighbors = getStopTextItemWithNeighbors(textItems, itemIndex);

                const matchInTextItem = sanitizedTextItemStr.match(matchString);
                const matchInNeighbors = textWithNeighbors.match(matchString);

                const stopFullMatch = sanitizedTextItemStr.match(stopString);
                const stopMatchInNeighbors = stopTextWithNeighbors.match(stopString);

                if (!shouldStopHighlight && (stopFullMatch || stopMatchInNeighbors)) {
                    setShouldStopHighlight(true);
                    setStopIndex(textItem.itemIndex);
                }

                if (!shouldHighlight && (matchInTextItem || matchInNeighbors)) {
                    setShouldHighlight(true);
                    setHighlightIndex(textItem.itemIndex);
                }

                if (shouldHighlight) {
                    if (itemIndex >= highlightIndex - 2 && itemIndex < stopIndex) {
                        const highlightedText = highlightPattern(textItem.str, textItem.str);
                        return highlightedText;
                    }
                }
            }
            return textItem.str;
        },
        [highlightIndex, props.citationPage, shouldHighlight, citationInfo, stopIndex, textItems, shouldStopHighlight]
    );

    const client = useLogin ? useMsal().instance : undefined;

    const fetchCitation = async () => {
        const token = client ? await getAppServiceToken(client) : undefined;
        if (props.activeCitation && props.activeCitation !== currentCitation) {
            setCurrentCitation(props.activeCitation.split("#")[0]);
            // Get hash from the URL as it may contain #page=N
            // which helps browser PDF renderer jump to correct page N
            const originalHash = props.activeCitation.indexOf("#") ? props.activeCitation.split("#")[1] : "";
            const fileAndAgentType = props.activeCitation.split("#")[0] + `?agent-type=${props.agent.type}`;
            const response = await fetch(fileAndAgentType, {
                method: "GET",
                headers: getHeaders(token)
            });
            const citationContent = await response.blob();
            let citationObjectUrl = URL.createObjectURL(citationContent);
            // Add hash back to the new blob URL
            if (originalHash) {
                citationObjectUrl += "#" + originalHash;
            }
            setCitationObjectUrl(citationObjectUrl);
        }
    };

    return (
        <div className={styles.pdfContainer}>
            <div className={props.darkMode ? styles.buttonsDark : styles.buttons}>
                <div className={styles.zoomButtons}>
                    <PlusButton darkMode={props.darkMode} onClick={handleZoomIn} />
                    <MinusButton darkMode={props.darkMode} onClick={handleZoomOut} />
                    <DownloadButton darkMode={props.darkMode} onClick={handleDownload} />
                </div>
                <CloseButton darkMode={props.darkMode} onClick={props.onClose} />
            </div>
            <div className={styles.pdfPages} ref={pdfContainerRef}>
                <Document file={citationObjectUrl} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(new Array(numPages), (el, index) => {
                        const pageNumber = index + 1;
                        return (
                            <div key={`page_${pageNumber}`} className={styles.page}>
                                <Page
                                    pageNumber={pageNumber}
                                    data-page-number={pageNumber}
                                    width={pageWidth}
                                    onLoadSuccess={onPageLoadSuccess}
                                    customTextRenderer={pageNumber === props.citationPage ? customTextRendererIndexHighlight : undefined}
                                />
                            </div>
                        );
                    })}
                </Document>
            </div>
        </div>
    );
};
