import { useState } from "react";
import { RemoveButton } from "./RemoveButton";
import styles from "./FilesField.module.scss";

interface FileTagProps {
    fileName: string;
    onRemove: (fileName: string) => void;
}

export const FileTag = (props: FileTagProps) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    return (
        <div className={styles.filetagContainer}>
            <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} className={styles.filetagContainer2}>
                <span className={`${styles.filetagTooltip} ${showTooltip ? "show" : ""}`}>{props.fileName}</span>

                <div className={styles.tagText}>{props.fileName}</div>
            </div>
            <div className={styles.tagMargin}>
                <RemoveButton onClick={() => props.onRemove(props.fileName)} />
            </div>
        </div>
    );
};
