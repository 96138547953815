import styles from "./AgentDescription.module.scss";

type AgentDescriptionProps = {
    agentName: string;
    agentDescription: string;
    darkMode: boolean;
    isMobile: boolean;
};

export const AgentDescription = (props: AgentDescriptionProps) => {
    return (
        <div className={styles.descriptionContainer}>
            <div className={[
                 props.darkMode ? styles.descriptionCardDark : styles.descriptionCard,
                 props.isMobile ? styles.mobile : ""
                ].join(" ")}>
                <div className={styles.descriptionTitle}>{props.agentName}</div>
                <div className={styles.descriptionText}>{props.agentDescription}</div>
            </div>
        </div>
    );
};
