import { Delete24Regular, Delete48Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./ClearChatButton.module.scss";
import { useGlobalTranslation } from "../../useGlobalTranslation";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
    darkMode: boolean;
}

export const ClearChatButton = ({ className, disabled, onClick, darkMode }: Props) => {
    const t = useGlobalTranslation();
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <div className={darkMode ? styles.tooltipDark : styles.tooltip}>
                <span className={darkMode ? styles.tooltiptextDark : styles.tooltiptext}>{t("clearChatBtn_tooltip")}</span>
                <Button
                    style={darkMode ? { color: "#fbfbfe" } : {}}
                    icon={<Delete24Regular style={{ height: "40px", width: "40px" }} />}
                    disabled={disabled}
                    onClick={onClick}
                />
            </div>
        </div>
    );
};
