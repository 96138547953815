import { useState } from "react";
import { Landingpage } from "../landingpage/Landingpage";
import { AccountInfo } from "@azure/msal-browser";
import Agents from "../agents/Agents";

export function Frontpage(): JSX.Element {
    const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);

    const handleAccount = (accountInfo: AccountInfo | null) => {
        setAccountInfo(accountInfo);
    };

    return <>{accountInfo ? <Agents /> : <Landingpage onActiveAccountChange={handleAccount} />}</>;
}

Frontpage.displayName = "Frontpage";
