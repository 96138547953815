import { Delete24Regular, Dismiss24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./Buttons.module.scss";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
    darkMode: boolean;
}

export const CloseButton = ({ className, disabled, onClick, darkMode }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <div className={darkMode ? styles.tooltipDark : styles.tooltip}>
                <span className={darkMode ? styles.tooltiptextDark : styles.tooltiptext} style={{ left: "-20px" }}>
                    Sulje
                </span>
                <Button style={darkMode ? { color: "#fbfbfe" } : {}} icon={<Dismiss24Regular />} disabled={disabled} onClick={onClick} />
                {/*<button onClick={onClick} className={styles.closeButton}>
                    <img src="/x-icon.svg" alt="remove icon" className={styles.icon}></img>
                </button>
    */}
            </div>
        </div>
    );
};
