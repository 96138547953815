import { useEffect, useRef, useState } from "react";
import styles from "./AgentDropdown.module.scss";
type DropdownProps = {
    options: string[];
    default: string;
    onSelect: (index: number) => void;
    darkMode: boolean;
};

export const AgentDropdown = (props: DropdownProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>(props.default);
    const handleButtonClick = () => {
        setOpen(!open);
    };
    const ref = useOutsideClick(() => {
        if (open) {
            setOpen(false);
        }
    });

    const handleSelect = (option: string) => {
        setSelectedOption(option);
        setOpen(false);
    };

    return (
        <div ref={ref} className="">
            <button onClick={handleButtonClick} className={props.darkMode ? styles.dropdownButtonDark : styles.dropdownButton}>
                {selectedOption}
            </button>
            {open && (
                <div className={props.darkMode ? styles.dropdownContainerDark : styles.dropdownContainer}>
                    {props.options.map((option, index) => (
                        <div
                            key={index}
                            className={props.darkMode ? styles.dropdownItemDark : styles.dropdownItem}
                            onClick={() => {
                                handleSelect(option);
                                props.onSelect(index);
                            }}
                        >
                            <span className="">
                                <span className={styles.agentNameText}>{option}</span>
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const useOutsideClick = (callback: () => void) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [callback]);

    return ref;
};
