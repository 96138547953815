import { AddCircle24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./AddAgentButton.module.scss";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
    darkMode: boolean;
}

export const AddAgentButton = ({ className, disabled, onClick, darkMode }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <div className={darkMode ? styles.tooltipDark : styles.tooltip}>
                <span className={darkMode ? styles.tooltiptextDark : styles.tooltiptext} style={{ left: "-11px" }}>
                    Lisää agentti
                </span>
                <Button style={darkMode ? { color: "#fbfbfe" } : {}} icon={<AddCircle24Regular />} disabled={disabled} onClick={onClick} />
            </div>
        </div>
    );
};
