import { Toggle } from "@fluentui/react";
import styles from "./ThemeToggler.module.scss";
import { useDarkMode } from "../../DarkModeContext";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
interface ThemeTogglerProps {
    darkMode: boolean;
    show?: boolean;
}
export const ThemeToggler = ({ darkMode, show}: ThemeTogglerProps) => {
    const { toggleDarkMode } = useDarkMode();


    return (
        <div className={[styles.darkmodeToggle, !show ? "d-none d-lg-flex":""].join(" ")}>
                            <div className={styles.iconContainer}>
                                <SunIcon style={{ color: "#f8f8f5" }} />
                            </div>

                            <Toggle checked={darkMode} onChange={toggleDarkMode} className="mb-0" />
                            <div className={styles.iconContainer}>
                                <MoonIcon style={darkMode ? {} : { color: "black" }} />
                            </div>
                        </div>
    );
};
