import { useRef, useState } from "react";
import { Agent } from "../../types/agent";
import Chat from "../chat/Chat";
import styles from "./Agents.module.scss";
import Carousel from 'react-bootstrap/Carousel'
import useWindowDimensions from "../../services/viewportService";
import { useDarkMode } from "../../DarkModeContext";
import { useGlobalTranslation } from '../../useGlobalTranslation';


const Agents = () => {
    const t = useGlobalTranslation();
    const { width } = useWindowDimensions();
    const [columnPlacementIndex, setColumnPlacementIndex] = useState<number>(0);
    const [columnId, setColumnId] = useState<number>(3);
    const agentRootRef = useRef<HTMLDivElement | null>(null);
    const agentsRef = useRef<HTMLDivElement | null>(null);
    const { darkMode } = useDarkMode();
    const [chatColumns, setChatColumns] = useState<{ number: number; id: number }[]>([
        {
            number: 0,
            id: 0
        }
    ]);
    const agents: Agent[] = [
        {
            type: "Basic",
            name: t("agent.basic.name"),
            apiEndpoint: "/secure_chat",
            description: t("agent.basic.description"),
            model: "gpt-3.5"
        },
        {
            type: "Basic",
            name: t("agent.basic2.name"),
            apiEndpoint: "/chat",
            description: t("agent.basic2.description"),
            model: "gpt-4"
        },
        {
            type: "FileReader",
            name: t("agent.fileReader.name"),
            apiEndpoint: "/chat",
            searchIndex: "user-document-index",
            description: t("agent.fileReader.description"),
            model: "gpt-3.5"
        },
        {
            type: "FileReader",
            name: t("agent.fileReader2.name"),
            apiEndpoint: "/chat",
            searchIndex: "user-document-index",
            description: t("agent.fileReader2.description"),
            model: "gpt-4"
        },
        {
            type: "Azure",
            name: t("agent.azure.name"),
            apiEndpoint: "/chat",
            searchIndex: "gptkbindex",
            description: t("agent.azure.description"),
            model: "gpt-3.5"
        },
        {
            type: "Azure",
            name: t("agent.azure2.name"),
            apiEndpoint: "/chat",
            searchIndex: "gptkbindex",
            description: t("agent.azure2.description"),
            model: "gpt-4"
        }
    ];


    const handleAdd = (index: number) => {
        setColumnPlacementIndex(index);
        setColumnId(columnId + 1);
        const newID = columnId + 1;
        const newColumn = {
            number: newID <= 2 ? newID : 0,
            id: newID
        };
        setChatColumns(prevChatColumns => {
            const updatedColumns = [...prevChatColumns];
            updatedColumns.splice(index, 0, newColumn);
            return updatedColumns;
        });

        if (agentsRef.current) {
            const addedAgentElement = agentsRef.current.children[index];
            const isLast = index === agentsRef.current.children.length;

            if (isLast) {
                setTimeout(() => {
                    const addedAgentElement = agentsRef.current?.children[index];
                    addedAgentElement?.scrollIntoView({ behavior: "smooth", block: "end" });
                }, 500);
            } else {
                addedAgentElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
            }
        }
    };

    const handleRemove = (columnToRemove: number) => {
        if (chatColumns.length > 1) {
            const updatedColumns = chatColumns.filter(column => column.id !== columnToRemove);
            setChatColumns(updatedColumns);
        }
    };

    const isMobile = () => {
        return width < 760;
    }

    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };

    let currentIndex = 1;

    if (isMobile()) {
        return (
            // This is the mobile view
            <div ref={agentRootRef} className={[styles.containerMobile, "justify-content-around flex-row mx-0 px-0"].join(" ")}>
                <div ref={agentsRef} className={[styles.agentsContainer, "mx-0 px-0 row w-100"].join(" ")}>
                    <div className={[darkMode ? styles.lightText : styles.darkText, "row text-center dark"].join(" ")}>
                        {chatColumns?.length > 1 &&
                            <span>Chat {index + 1} / {chatColumns.length}</span>
                        }
                    </div>
                    <Carousel touch interval={null} controls={false} activeIndex={index} onSelect={handleSelect}
                        className={[styles.carouselMobile, !darkMode ? styles.carouselMobileLight : "", "d-lg-none h-100"].join(" ")}>
                        {chatColumns.map((agent, index) => (
                            <Carousel.Item key={agent.id + "_item"} className="h-100">
                                <Chat
                                    key={agent.id}
                                    agent={agents}
                                    index={agent.number}
                                    add={() => handleAdd(index + 1)}
                                    remove={() => handleRemove(agent.id)}
                                    arrayIndex={index}
                                    agentRootRef={agentRootRef}
                                    agentCount={chatColumns.length}
                                    isActive={false}
                                    handleSetActiveAgent={() => console.log("active")}
                                    isMobile={true}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div>
        );
    }

    return (
        <div ref={agentRootRef} className={[styles.container, "justify-content-around flex-row mx-0 px-0"].join(" ")}>
            <div ref={agentsRef} className={[styles.agentsContainer, "mx-0 px-0"].join(" ")}>
                {chatColumns.map((agent, index) => (
                    <Chat
                        key={agent.id}
                        agent={agents}
                        index={agent.number}
                        add={() => handleAdd(index + 1)}
                        remove={() => handleRemove(agent.id)}
                        arrayIndex={index}
                        agentRootRef={agentRootRef}
                        agentCount={chatColumns.length}
                        isActive={false}
                        handleSetActiveAgent={() => console.log("active")}
                        isMobile={false}
                    />
                ))}
            </div>
        </div>
    );
};

export default Agents;
