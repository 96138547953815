interface AlertProps {
    alert: string;
    open: boolean;
}

import styles from "./AlertBox.module.scss";
export const AlertBox = (props: AlertProps) => {
    return (
        <div className={styles.container + (!props.open && styles.hidden)}>
            <div className={styles.inner}>
                <div className={styles.flexContainer}>
                    <div className={styles.alertContainer}>
                        <div>{props.alert}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
