interface UploadedFilesProps {
    failed: string[];
    successful: string[];
    alreadyInIndex: string[];
    open: boolean;
}

import { useGlobalTranslation } from "../../useGlobalTranslation";
import styles from "./FileUpload.module.scss";

export const UploadedFilesStatus = (props: UploadedFilesProps) => {
    const t = useGlobalTranslation();
    return (
        <div className={styles.fileStatusContainer + (props.open ? styles.shown : styles.hidden)}>
            <div className={styles.fileStatusContainer2}>
                {props.successful.length > 0 && (
                    <div className={styles.fileStatus}>
                        <div className={styles.fileStatusImg}>
                            <img src="/img/successful-file-icon.svg" alt="pdf icon" />
                        </div>
                        <div className={styles.fileStatusTextContainer}>
                            <span className={styles.fileStatusText}>{t("uploadedFilesStatus.uploaded")}</span>
                            {props.successful.map((name, index) => (
                                <div className={styles.fileName} key={index}>
                                    {name}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {props.failed.length > 0 && (
                    <div className={styles.fileStatus}>
                        <div className={styles.fileStatusImg}>
                            <img src="/img/failed-file-icon.svg" alt="pdf icon" />
                        </div>
                        <div className={styles.fileStatusTextContainer}>
                            <span className={styles.fileStatusText}>{t("uploadedFilesStatus.unsupported")}</span>
                            {props.failed.map((name, index) => (
                                <div className={styles.fileName} key={index}>
                                    {name}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {props.alreadyInIndex.length > 0 && (
                    <div className={styles.fileStatus}>
                        <div className={styles.fileStatusImg}>
                            <img src="/img/successful-file-icon.svg" alt="pdf icon" />
                        </div>
                        <div className={styles.fileStatusTextContainer}>
                            <span className={styles.fileStatusText}>{t("uploadedFilesStatus.alreadyLoaded")}</span>
                            {props.alreadyInIndex.map((name, index) => (
                                <div className={styles.fileName} key={index}>
                                    {name}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
