import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.scss";
import { useLogin } from "../../authConfig";
import { LoginButton } from "../../components/LoginButton";
import { ThemeToggler } from "../../components/ThemeToggler";
import { useMsal } from "@azure/msal-react";
import { useDarkMode } from "../../DarkModeContext";
import { useEffect, useState  } from "react";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Layout = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const { darkMode } = useDarkMode();
    useEffect(() => {
        if (darkMode) {
            document.body.classList.add("darkMode");
        } else {
            document.body.classList.remove("darkMode");
        }
    }, [darkMode]);
    if (activeAccount) {
        return (
            <div className={styles.layout}>
                <header className={[darkMode ? styles.headerDark : styles.header, "navbar navbar-expand-lg bd-navbar sticky-top"].join(" ") } role={"banner"}>
                    <div className={[styles.headerContainer, "row col-12"].join(" ")}>
                        <div className="row justify-content-between ms-2 col-6">
                            <Link to="" className={[darkMode ? styles.headerTitleContainerDark : styles.headerTitleContainer, "row col"].join(" ")}>
                                <div title="Innofactor logo" className={[darkMode ? styles.logoDark : styles.logoLight, styles.logo,"pe-0 w-auto"].join(" ")}></div>
                            </Link>
                        </div>
                        
                        <ThemeToggler darkMode={darkMode}/>
                        <div className="d-none d-lg-flex col-2 flex-row-reverse">{useLogin && <LoginButton darkMode={darkMode} />}</div>
                        <Button className={[styles.btnburger,"d-lg-none p-0", "col-1"].join(" ")} onClick={handleShow}>
                            <i style={!darkMode? { color: "black" } : {}} className={[styles.bi,"bi bi-three-dots"].join(" ")}/>
                        </Button>
                    </div>

                    <Offcanvas show={show} placement="end" onHide={handleClose} className={darkMode ? styles.dark : styles.light}>
                        <Offcanvas.Header>
                        <Offcanvas.Title>GPT Agents</Offcanvas.Title>
                        <i role='button' onClick={handleClose} 
                            className={[!darkMode ? styles.buttonClose: "", "bi bi-x-lg ms-auto me-1 fs-3"].join(" ")} />
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                        {<ThemeToggler darkMode={darkMode} show={true}/>}
                      <hr/>
                        {useLogin && <LoginButton darkMode={darkMode} />}
                        </Offcanvas.Body>
                    </Offcanvas>
                </header>
                <Outlet />
            </div>
        );
    } else {
        return <Outlet />;
    }
};

export default Layout;
