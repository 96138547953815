import { useEffect, useRef, useState } from "react";
import { ViewFilesButton } from "../ViewFilesButton/ViewFilesButton";
import styles from "./UserFilesModal.module.scss";
import { ArrowSort24Regular } from "@fluentui/react-icons";
import { deleteUserFiles, fetchUserFiles } from "../../api";
import { useMsal } from "@azure/msal-react";
import { getToken } from "../../authConfig";
import { useGlobalTranslation } from "../../useGlobalTranslation";

type UserFilesProps = {
    onSelect: (file: string[]) => void;
    lockedFiles: string[];
    isActive: boolean;
    darkMode: boolean;
    agentType: string;
};

type UserFile = {
    sourcefile: string;
    time: string;
};

const UserFilesModal = (props: UserFilesProps) => {
    const t = useGlobalTranslation();
    //const [fileNames, setFileNames] = useState<UserFile[]>([]);
    const [fileNames, setFileNames] = useState<UserFile[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const [hasFetchedData, setHasFetchedData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fileSortOpen, setFileSortOpen] = useState(false);
    const fileSortOptions: string[] = [
        t("userFilesModal.sortOptions.alphabetical"),
        t("userFilesModal.sortOptions.newest"),
        t("userFilesModal.sortOptions.oldest"),
        ];
    const [selectedSorting, setSelectedSorting] = useState<string>("");
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const client = useMsal().instance;

    const openModal = () => {
        setIsModalOpen(true);

        fetchFiles();
    };

    const handleFileSortClick = () => {
        setFileSortOpen(!fileSortOpen);
    };

    const handleDeleteFilesClick = async () => {
        setIsDeleting(true);
        const token = client ? await getToken(client) : undefined;
        try {
            const req = await deleteUserFiles(token?.accessToken, selectedFiles, props.agentType);
            setSelectedFiles([]);
            fetchFiles();
        } catch (error) {
            console.error(error);
        } finally {
            setIsDeleting(false);
        }
    };

    const fetchFiles = async () => {
        const token = client ? await getToken(client) : undefined;

        try {
            setIsLoading(true);
            const req = await fetchUserFiles(token?.accessToken, props.agentType);
            if (req.ok) {
                const data: any[] = await req.json();
                if (data) {
                    const userFiles = data.map((file: any) => {
                        return {
                            sourcefile: file.sourcefile,
                            time: file.time
                        };
                    });
                    setFileNames(userFiles);
                    sortFiles(selectedSorting, fileNames);
                    setHasFetchedData(true);
                } else {
                    console.log("No 'filenames' found in the response.");
                }
            } else {
                console.log("Request failed with status: " + req.status);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setSelectedFiles(props.lockedFiles);
    }, [props.lockedFiles, isModalOpen]);

    const ref = useOutsideClick(() => {
        if (isModalOpen) {
            setIsModalOpen(false);
        }
    });

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log("submitted");
        props.onSelect(selectedFiles);
        setSelectedFiles([]);
        closeModal();
    };

    const toggleSelection = (filename: string) => {
        if (selectedFiles.includes(filename)) {
            setSelectedFiles(prevSelectedFiles => prevSelectedFiles.filter(file => file !== filename));
        } else {
            setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, filename]);
        }
    };

    const handleSortingSelect = (sortingMethod: string) => {
        setSelectedSorting(sortingMethod);
        setFileSortOpen(false);
        sortFiles(sortingMethod, fileNames);
    };

    const sortFiles = (method: string, files: UserFile[]) => {
        switch (method) {
            case t("userFilesModal.sortOptions.alphabetical"):
                return files.slice().sort((a, b) => a.sourcefile.localeCompare(b.sourcefile));
            case t("userFilesModal.sortOptions.newest"):
                return files.slice().sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
            case t("userFilesModal.sortOptions.oldest"):
                return files.slice().sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());
            default:
                return files.slice().sort((a, b) => a.sourcefile.localeCompare(b.sourcefile));
        }
    };

    const colorClass = props.isActive ? "text-slate-100" : "text-slate-gray";

    return (
        <div>
            <ViewFilesButton onClick={openModal} darkMode={props.darkMode} />
            {isModalOpen && (
                <div className={styles.filesModal}>
                    <div className={styles.container}>
                        <div className={styles.container2} aria-hidden="true">
                            <div className={props.darkMode ? styles.container3Dark : styles.container3}></div>
                        </div>

                        <span className={styles.customHiddenSpan} aria-hidden="true">
                            &#8203;
                        </span>

                        <div ref={ref} className={props.darkMode ? styles.container4Dark : styles.container4}>
                            <div className={[props.darkMode ? styles.container5Dark : styles.container5 , 'mt-5'].join(" ")}>
                                <div className={props.darkMode ? styles.container6Dark : styles.container6}>
                                    <span className="text-xl">{t("userFilesModal.header")}</span>
                                    <div ref={ref} className="relat-ive animate-down-fade">
                                        <button onClick={handleFileSortClick} className={props.darkMode ? styles.customButtonDark : styles.customButton}>
                                            {selectedSorting.length > 0 ? `${t("userFilesModal.selectedOrder")} ${selectedSorting}` : t("userFilesModal.selectedDefault")}
                                            <ArrowSort24Regular className={styles.icon} />
                                        </button>
                                        {fileSortOpen && (
                                            <div className={props.darkMode ? styles.sortDropdownDark : styles.sortDropdown}>
                                                {fileSortOptions.map((option, index) => (
                                                    <div
                                                        onClick={() => {
                                                            handleSortingSelect(option);
                                                        }}
                                                        key={index}
                                                        className={props.darkMode ? styles.sortingItemsDark : styles.sortingItems}
                                                    >
                                                        <span className={styles.sortingText}>
                                                            <span className={styles.textTruncate}>{option}</span>
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <span className="text-xl">{t("userFilesModal.selectedFiles")}</span>
                                </div>

                                <form onSubmit={handleFormSubmit}>
                                    {isLoading || isDeleting ? (
                                        <div className={props.darkMode ? styles.spinnerContainerDark : styles.spinnerContainer}>
                                            <svg fill="none" className={styles.animateSpin} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    clipRule="evenodd"
                                                    d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                                                    fill="currentColor"
                                                    fillRule="evenodd"
                                                />
                                            </svg>
                                            <div>{isDeleting ? t("userFilesModal.deletingFiles") : t("userFilesModal.loadingFiles")}</div>
                                        </div>
                                    ) : (
                                        <div className={styles.fileContainer}>
                                            {sortFiles(selectedSorting, fileNames).map((filename, index) => (
                                                <div key={index} className={props.darkMode ? styles.filenameDark : styles.filename}>
                                                    <div>{filename.sourcefile}</div>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedFiles.includes(filename.sourcefile)}
                                                        onChange={() => toggleSelection(filename.sourcefile)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <div className={props.darkMode ? styles.buttonContainerDark : styles.buttonContainer}>
                                        <button
                                            type={"button"}
                                            onClick={handleDeleteFilesClick}
                                            className={props.darkMode ? styles.formButtonDark : styles.formButton}
                                        >
                                            {isDeleting ? t("userFilesModal.deleting") : t("userFilesModal.deleteSelected")}
                                        </button>
                                        <button type={"submit"} className={props.darkMode ? styles.formButtonDark : styles.formButton}>
                                           {t("userFilesModal.lockSelected")}
                                        </button>

                                        <button onClick={closeModal} className={props.darkMode ? styles.formButtonDark : styles.formButton}>
                                            {t("userFilesModal.close")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserFilesModal;

const useOutsideClick = (callback: () => void) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [callback]);

    return ref;
};
