const BACKEND_URI = "";

import { ChatAppResponse, ChatAppResponseOrError, ChatAppRequest } from "./models";
import { useLogin } from "../authConfig";

export function getHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    // If using login, add the id token of the logged in account as the authorization
    if (useLogin) {
        if (idToken) {
            headers["Authorization"] = `Bearer ${idToken}`;
        }
    }

    return headers;
}

export async function askApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/ask`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function chatApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export async function chatSecureApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/secure_chat`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

export async function uploadFile(formData: FormData, idToken: string | undefined, agentType: string) {
    const headers = getHeaders(idToken);
    delete headers["Content-Type"]; // Remove the Content-Type header if it's set because of form data

    return await fetch(`${BACKEND_URI}/upload?agent-type=${agentType}`, {
        method: "POST",
        headers: headers,
        body: formData
    });
}

export async function fetchUserFiles(idToken: string | undefined, agentType: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/user-files?agent-type=${agentType}`, {
        method: "GET",
        headers: getHeaders(idToken)
    });
}

export async function deleteUserFiles(idToken: string | undefined, fileNames: string[], agentType: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/user-files-delete?agent-type=${agentType}`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(fileNames)
    });
}

export async function downloadFile(idToken: string | undefined, url: string, agentType: string): Promise<void> {
    try {
        const splitIndex = url.lastIndexOf("#");
        const splitFilename = url.slice(0, splitIndex);
        const fileName = splitFilename.replace("/content/", "");
        const response = await fetch(`/download/${fileName}?agentType=${agentType}`, {
            method: "GET",
            headers: getHeaders(idToken)
        });
        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } else {
            console.log("Error: ", response.statusText);
        }
    } catch (e) {
        console.log("Could not fetch file: ", e);
    }
}
