import { DefaultButton } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";

import styles from "./LoginButton.module.scss";
import { getRedirectUri, loginRequest } from "../../authConfig";
import { useGlobalTranslation } from "../../useGlobalTranslation";
interface LoginButtonProps {
    darkMode: boolean;
}
export const LoginButton = ({ darkMode }: LoginButtonProps) => {
    const t = useGlobalTranslation();
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const handleLoginPopup = () => {
        /**
         * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
         * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
         * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
         */
        instance
            .loginPopup({
                ...loginRequest,
                redirectUri: getRedirectUri()
            })
            .catch(error => console.log(error));
    };
    const handleLogoutPopup = () => {
        instance
            .logoutPopup({
                mainWindowRedirectUri: "", // redirects the top level app after logout
                account: instance.getActiveAccount()
            })
            .catch(error => console.log(error));
    };
    const logoutText = t("loginButton.logout");

    return (
        <DefaultButton
            text={activeAccount ? logoutText : t("loginButton.login")}
            className={darkMode ? styles.loginButtonDark : styles.loginButton}
            onClick={activeAccount ? handleLogoutPopup : handleLoginPopup}
        ></DefaultButton>
    );
};
