import { createContext, useContext, useEffect, useState } from "react";

type DarkModeContextProps = {
    darkMode: boolean;
    toggleDarkMode: () => void;
};

type Props = {
    children?: React.ReactNode;
};

export const DarkModeContext = createContext<DarkModeContextProps | undefined>(undefined);

export const DarkModeProvider: React.FC<Props> = ({ children }) => {
    const [darkMode, setDarkMode] = useState(false);
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    useEffect(() => {
        setDarkMode(mediaQuery.matches);
    }, []);
    const toggleDarkMode = () => {
        setDarkMode(prevDarkMode => !prevDarkMode);
    };

    return <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>{children}</DarkModeContext.Provider>;
};

export const useDarkMode = (): DarkModeContextProps => {
    const context = useContext(DarkModeContext);
    if (!context) {
        throw new Error("useDarkMode must be used within a DarkModeProvider");
    }
    return context;
};
