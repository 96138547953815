import { renderToStaticMarkup } from "react-dom/server";
import { getCitationFilePath } from "../../api";
import { to } from "@react-spring/core";
import styles from "./Answer.module.scss";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
};

function processCitations(
    part: string,
    citations: string[],
    onCitationClicked: (citationFilePath: string, citationNumber: number) => void
): string {
    const citationParts = part.split(/\[([^\]]+)\]/g);
    return citationParts
        .map((citationPart, citationIndex) => {
            if (citationIndex % 2 === 0) {
                return citationPart;
            } else {
                let citationIndex: number;
                if (citations.indexOf(citationPart) !== -1) {
                    citationIndex = citations.indexOf(citationPart) + 1;
                } else {
                    citations.push(citationPart);
                    citationIndex = citations.length;
                }

                const path = getCitationFilePath(citationPart);
                return renderToStaticMarkup(
                    <a className={styles.supContainer} title={citationPart}>
                        <sup>{citationIndex}</sup>
                    </a>
                    );
            }
        })
        .join("");
}

const createCodeBlockElement = (codeBlock: string, darkMode: boolean, renderAsHTML: boolean) => {
    return (
        <div className={darkMode ? styles.codeBlockDark : styles.codeBlock}>
            {renderAsHTML ? <div dangerouslySetInnerHTML={{ __html: codeBlock }} /> : <Markdown remarkPlugins={[remarkGfm]}>{codeBlock}</Markdown>}
        </div>
    );
};

function processCodeBlocks(part: string, darkMode: boolean, renderAsHTML: boolean): string {
    const codeBlockRegex = /(```[\s\S]*?```)/g;
    const codeBlockParts = part.split(codeBlockRegex);
    return codeBlockParts
        .map((codeBlockPart, index) => {
            if (index % 2 === 0) {
                return codeBlockPart;
            } else {
                return renderToStaticMarkup(createCodeBlockElement(codeBlockPart.slice(3, -3), darkMode, renderAsHTML));
            }
        })
        .join("");
}

export function parseAnswerToHtml(
    answer: string,
    isStreaming: boolean,
    agentType: string | undefined,
    onCitationClicked: (citationFilePath: string, citationNumber: number) => void,
    renderAsHTML: boolean,
    darkMode: boolean
): HtmlParsedAnswer {
    const citations: string[] = [];

    // trim any whitespace from the end of the answer after removing follow-up questions
    let parsedAnswer = answer.trim();

    // Omit a citation that is still being typed during streaming
    if (isStreaming && agentType !== "Basic") {
        let lastIndex = parsedAnswer.length;
        for (let i = parsedAnswer.length - 1; i >= 0; i--) {
            if (parsedAnswer[i] === "]") {
                break;
            } else if (parsedAnswer[i] === "[") {
                lastIndex = i;
                break;
            }
        }
        const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
        parsedAnswer = truncatedAnswer;
    }

    const codeBlockRegex = /(```[\s\S]*?```)/g;
    const parts = parsedAnswer.split(codeBlockRegex);
    const fragments: string[] = parts.map((part, index) => {
        if (agentType === "Basic") {
            // This is a code block, return it as is
            return processCodeBlocks(part, darkMode, renderAsHTML);
        } else {
            // This is not a code block, process it as before
            return processCitations(part, citations, onCitationClicked);
        }
    });

    return {
        answerHtml: fragments.join(""),
        citations
    };
}
