import { parseSupportingContentItem } from "./SupportingContentParser";

import styles from "./SupportingContent.module.scss";

interface Props {
    supportingContent: string[];
    darkMode: boolean;
}

export const SupportingContent = ({ supportingContent, darkMode }: Props) => {
    return (
        <ul className={styles.supportingContentNavList}>
            {supportingContent.map((x, i) => {
                const parsed = parseSupportingContentItem(x);

                return (
                    <li className={darkMode ? styles.supportingContentItemDark : styles.supportingContentItem}>
                        <h4 className={darkMode ? styles.supportingContentItemHeaderDark : styles.supportingContentItemHeader}>{parsed.title}</h4>
                        <p className={darkMode ? styles.supportingContentItemTextDark : styles.supportingContentItemText}>{parsed.content}</p>
                    </li>
                );
            })}
        </ul>
    );
};
